<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="interlock-steps">
      <van-steps
        active-color="#3278FF"
        direction="vertical"
        :active="list.length"
      >
        <van-step
          v-for="item in list"
          :key="item.id"
          @click.native="
            $router.push({
              name: 'danger-interlock-detail',
              params: { id: item.id }
            })
          "
        >
          <div class="interlock-step-header">
            <div class="interlock-step-title">
              {{ statusMap[item.stopStatus].label }}
            </div>
            <div class="interlock-step-right">
              验收人： {{ item.checkUserName || "--" }}
            </div>
          </div>
          <div class="interlock-step-content">
            计划停用时间：2022-07-28 14
          </div>
          <div class="interlock-step-content">
            计划投用时间：2022-07-28 14
          </div>
        </van-step>
      </van-steps>
    </div>
  </LoadListView>
</template>

<script>
const statusMap = {
  0: {
    label: "临时解除"
  },
  1: {
    label: "长期停用"
  },
  2: {
    label: "永久摘除"
  }
};
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    statusMap: () => statusMap
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.interlock {
  &-step-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &-step-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 22px;
  }

  &-step-right {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
  }

  &-step-content {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
  }

  &-steps {
    ::v-deep {
      .van-step__circle {
        width: 10px;
        height: 10px;
      }

      .van-steps--vertical {
        padding-left: 51px;
      }

      .van-step--vertical .van-step__circle-container,
      .van-step--vertical .van-step__line {
        left: -21px;
      }
    }
  }
}
</style>

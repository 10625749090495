<template>
  <div class="interlock-detail">
    <van-nav-bar
      title="联锁回路"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />
    <div v-if="detail.chainAccountVo" class="interlock-info">
      <div class="interlock-title">
        {{ detail.chainAccountVo.chainCircuitName }}
        <span
          class="interlock-status"
          :style="{
            background: statusMap[detail.chainAccountVo.useStatus].color
          }"
          >{{ statusMap[detail.chainAccountVo.useStatus].label }}</span
        >
      </div>
      <table class="interlock-body">
        <tr>
          <td class="interlock-label">联锁回路位号：</td>
          <td class="interlock-value">{{ chainCircuitBit }}</td>
        </tr>

        <tr>
          <td class="interlock-label">所属部门：</td>
          <td class="interlock-value">
            {{ detail.chainAccountVo.departmentName }}
          </td>
        </tr>

        <tr>
          <td class="interlock-label">联锁等级：</td>
          <td class="interlock-value">
            {{ detail.chainAccountVo.chainLevelName }}
          </td>
        </tr>
      </table>
    </div>
    <InterlockAccountList
      ref="interlock-account-list"
      :api="getInterlockPageChange"
    />
  </div>
</template>

<script>
import InterlockAccountList from "./components/InterlockAccountList.vue";
import { getChainAccountsDetail, getInterlockPageChange } from "./api";

const statusMap = {
  0: {
    label: "未投用",
    color: "#0FB8C5"
  },
  1: {
    label: "已投用",
    color: "#F6790F"
  }
};
export default {
  components: {
    InterlockAccountList
  },
  data() {
    return {
      detail: {},
      record: []
    };
  },
  computed: {
    statusMap: () => statusMap,
    chainCircuitBit() {
      const circuitBitVoList = this.detail.circuitBitVoList || [];
      return circuitBitVoList.map(i => i.chainCircuitBit).join(",");
    }
  },
  mounted() {
    const id = this.$route.params.id;
    getChainAccountsDetail(id, { orgCode: this.userInfo.orgCode }).then(res => {
      this.detail = res || {};
    });
  },
  methods: {
    getInterlockPageChange(query = {}) {
      return getInterlockPageChange({
        ...query,
        orgCode: this.userInfo.orgCode,
        chainAccountId: this.$route.params.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.interlock {
  &-info {
    padding: 16px;
    border-bottom: 8px solid #f3f5f8;
  }

  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 22px;
  }

  &-status {
    padding: 0 4px;
    border-radius: 2px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }

  &-body {
    border: none;
    margin-top: 8px;
  }

  &-label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5d5f63;
    line-height: 28px;
  }

  &-value {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #15171b;
    line-height: 28px;
  }
}
</style>
